<script>
import { mapGetters } from 'vuex';

import NumberMixin from '~/mixins/NumberMixin';

import DropdownSimple from '@/components/atoms/DropdownSimple';
import SortButton from '@/components/molecules/SortButton';

export default defineNuxtComponent({
  components: { DropdownSimple, SortButton },
  mixins: [NumberMixin],
  props: {
    ticketStatus: {
      type: Object,
      required: true,
    },
    ticketsNumber: {
      type: Number,
      required: true,
    },
    sortCriteria: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters('TicketsModuleStore', ['statuses']),
    ...mapGetters('LocationsStore', ['selectedLocation']),
  },
  data() {
    return {
      ticketStatusOptions: [],
    };
  },
  mounted() {
    this.setTicketStatusOptions();
  },
  watch: {
    statuses() {
      this.setTicketStatusOptions();
    },
  },
  methods: {
    setTicketStatusOptions() {
      this.ticketStatusOptions = [
        {
          name: 'All',
          description: 'All Tickets',
          action_name: 'All',
          weight: 3,
          count: undefined,
        },
        ...this.statuses,
      ];
    },
    onSortCriteriaChanged(sortParameter, selectedCriteria) {
      this.$emit('onSortCriteriaChanged', sortParameter, selectedCriteria);
    },
    changedStatus(event) {
      this.$emit('onTicketStatusChanged', event);
    },
    onClearFilters() {
      this.$emit('onClearFilters');
    },
  },
});
</script>

<template>
  <div class="flex flex-col">
    <div class="flex items-center">
      <div class="truncate text-4xl font-light">
        {{ ticketStatus.description }}
      </div>
      <div
        class="ml-4 flex h-12 w-12 items-center justify-center rounded-full bg-white text-xs text-neutral-1"
      >
        {{ abbreviate(ticketsNumber, 1) }}
      </div>
    </div>
    <div class="flex pt-4">
      <DropdownSimple
        class="mr-1 flex h-12 flex-1 rounded shadow-sm"
        mobile-label="Select the ticket status:"
        :basic="true"
        :checked="ticketStatus"
        :options="ticketStatusOptions"
        :component-classes="['bg-white']"
        @change-option="changedStatus"
      />
      <SortButton
        :sort-criteria="sortCriteria"
        class="ml-1 flex h-12 flex-1"
        @sort-criteria-changed="onSortCriteriaChanged"
        @on-clear-filters="onClearFilters"
      />
    </div>
  </div>
</template>
