<script>
import { mapGetters, mapState } from 'vuex';

import DisplayMixin from '@/mixins/DisplayMixin';
import MobileDropdownControllerMixin from '@/mixins/MobileDropdownControllerMixin';
import { fetchAvatarImage } from '@/utilities/avatar-utilities';
import { formatPriority } from '~/features/tickets/utils/common';

import Icon from '@/components/atoms/Icon';
import Label from '@/components/atoms/Label';
import PriorityIcon from '@/components/atoms/PriorityIcon';

const iconNames = {
  ticketNew: 'tickets',
  ticketEdit: 'tickets',
  registration: 'registration',
  registrationAnnounce: 'profile',
  tasks: 'tasks',
  Medium: 'priorityMedium',
  Low: 'priorityLow',
  High: 'priorityHigh',
  form: 'instructions',
};

const labels = {
  Open: 'blue',
  Closed: 'gray',
  Pending: 'yellow',
};

export default defineNuxtComponent({
  name: 'DropdownSimple',
  components: { Icon, Label, PriorityIcon },
  mixins: [MobileDropdownControllerMixin, DisplayMixin],
  emits: ['changeOption'],
  props: {
    label: {
      type: String,
      default: '',
      required: false,
    },
    mobileLabel: {
      type: String,
      default: '',
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    options: {
      type: Array,
      required: true,
    },
    checked: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    icon: {
      type: Boolean,
      required: false,
      default: false,
    },
    optionIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    optionIconType: {
      type: String,
      required: false,
      default: '',
    },
    image: {
      type: Boolean,
      required: false,
    },
    big: {
      type: Boolean,
      required: false,
    },
    basic: {
      type: Boolean,
      required: false,
    },
    priority: {
      type: Boolean,
      required: false,
    },
    labeled: {
      type: String,
      required: false,
      default: '',
    },
    componentClasses: {
      type: Array,
      required: false,
      default: () => ['bg-neutral-3', 'h-full'],
    },
    topMenu: {
      type: Boolean,
      required: false,
      default: false,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    extraClass: {
      type: Array,
      required: false,
      default: () => ['sm:w-2/3'],
    },
  },
  computed: {
    ...mapState('ContextStore', {
      color: state => state.accentColor,
      darkMode: state => state.darkMode,
    }),
    ...mapGetters('MobileDropdownModalStore', ['dropdownOpened']),
  },
  mounted() {
    this.closeDropdownMenu();
  },
  methods: {
    formatPriority,
    fetchAvatarImage,
    isChecked(element) {
      if (this.checked && element.id)
        return element.id === this.checked.id;
      else if (this.checked && element.name)
        return this.checked && element.name === this.checked.name;
      else
        return false;
    },
    changeOption(newOption) {
      this.$emit('changeOption', newOption);
      this.setDropdownChecked(this.checked);
      this.toggleDropdownMenu();
    },

    getIconName() {
      if (iconNames[this.checked.icon])
        return iconNames[this.checked.icon];

      if (this.icon)
        return this.checked.icon;
    },

    getLabel() {
      return labels[this.labeled];
    },
    closeDropdownMenu() {
      if (this.dropdownOpened) {
        this.setDropdownOpened(false);
        this.setOpenModalCurrentContext('');
      }
    },
    toggleDropdownMenu() {
      if (this.dropdownOpened)
        this.closeDropdownMenu();
      else
        this.openMobileDropdown();
    },
    openMobileDropdown() {
      this.setDropdownOpened(true);
      this.setDropdownType('simple');
      this.setDropdownLabel(this.mobileLabel);
      this.setOpenModalCurrentContext(
        `dropdownMenu${this.label}${this.mobileLabel}`,
      );
      this.setDropdownOptions(this.options);
      this.setDropdownChecked(this.checked);
      this.setOptionsWithImage(this.image);
      this.setOptionIcon(this.optionIcon);
      this.setOptionIconType(this.optionIconType);
      this.setOnOptionChangedCallback((changedOption) => {
        this.changeOption(changedOption);
      });
    },
  },
});
</script>

<template>
  <div class="relative flex flex-col">
    <div
      v-if="label !== ''"
      class="mb-2 inline-block font-medium dark:text-white"
      :class="{ 'text-neutral-2': disabled }"
    >
      {{ label }} <span v-if="required" class="text-error-500">*</span>
    </div>
    <div
      class="dark:bg-dark-search-input-background flex h-14 cursor-pointer flex-row items-center justify-between rounded border border-transparent px-4 focus:border-primary dark:text-white"
      :class="[
        isDropdownOpen(`dropdownMenu${label}${mobileLabel}`)
          ? 'border-primary'
          : 'border-transparent',
        ...componentClasses,
      ]"
      @click="toggleDropdownMenu"
    >
      <div class="flex flex-row items-center">
        <Icon
          v-if="icon && getIconName()"
          :name="getIconName()"
          :dark-mode="darkMode"
          class="mr-4"
        />
        <PriorityIcon
          v-if="priority"
          :priority="formatPriority(checked)"
          :default-size="false"
          class="mr-2 h-8 w-8 shadow-md"
        />
        <trailblazer-avatar
          v-if="image && checked.name"
          :name="checked.name"
          :href="fetchAvatarImage(checked.avatar)"
          class="mr-4"
        />
        <div class="flex flex-col">
          <a v-if="basic || big" class="max-w-48 truncate">{{
            checked.name
          }}</a>
          <a
            v-if="checked.name === '' || checked.name === undefined"
            class="max-w-48 truncate text-neutral-4-800"
          >
            {{ placeholder }}
          </a>
          <a v-if="big" class="text-neutral-2">{{ __('Group') }}</a>
          <Label v-if="labeled" :color="getLabel()" :label="labeled" />
        </div>
      </div>

      <trailblazer-icon
        name="chevron-down"
        :accent="disabled ? '#A0A4A7' : color"
        class="inline-block"
      />
    </div>
    <div
      v-if="
        isDropdownOpen(`dropdownMenu${label}${mobileLabel}`)
          && ['desktop', 'tablet'].includes(getDisplayMode)
      "
      class="dark:bg-dark-search-input-background absolute z-10 max-h-700% min-w-48 overflow-auto overscroll-contain bg-neutral-4 shadow dark:text-white sm:right-0 md:left-0 md:w-full"
      :class="[
        {
          'mt-28': big,
          'mt-24': !big,
          'top-placement': topMenu && getDisplayMode === 'tablet',
        },
        ...extraClass,
      ]"
    >
      <perfect-scrollbar>
        <div
          v-for="option in options"
          :key="option.name + checked"
          class="dark:hover:bg-dark-submenu-item-background flex cursor-pointer flex-row items-center justify-start p-4 hover:bg-neutral-3"
          @click="changeOption(option)"
        >
          <trailblazer-avatar
            v-if="image"
            :name="option.name"
            :href="fetchAvatarImage(option.avatar)"
            class="mr-4"
          />
          <PriorityIcon
            v-if="priority"
            :priority="formatPriority(option)"
            :default-size="false"
            class="mr-6 h-8 w-8 shadow-md"
          />
          <Icon
            v-if="optionIcon"
            :name="option.icon"
            class="mr-8 place-self-center"
          />
          <a class="truncate" :class="{ 'text-primary': option === checked }">{{
            option.name
          }}</a>
          <Icon
            :size="20"
            :name="
              isChecked(option) ? 'radioButtonChecked' : 'radioButtonDefault'
            "
            class="ml-auto"
          />
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</template>

<style scoped>
.top-placement {
  bottom: 58px;
}
.ps {
  max-height: 275px;
}
</style>
